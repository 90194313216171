import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Row from "../components/bootstrap/row"

import AwardCategoryShortlist from "../components/award-category-shortlist"
import ShortlistOrganisation from "../components/shortlist-organisation"

const ShortlistPage = () => (
  <Background>
    <Layout>
      <SEO title="Shortlist" />
      <Container>
        <PageTitle text="Shortlist" />
        <p style={{
          textTransform: `uppercase`,
          color: `#fff`,
          fontWeight: `bold`,
          textAlign: `center`,
          maxWidth: `750px`,
          margin: `auto`,
          marginBottom: `50px`
        }}>Congratulations to all organisations who have made it to the shortlist.<br /><br />Choose between people, places, processes, technology and special<br />to see the shortlisted categories.</p>

        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="places-tab" data-toggle="pill" href="#places" role="tab" aria-controls="places" aria-selected="true">Places</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="people-tab" data-toggle="pill" href="#people" role="tab" aria-controls="people" aria-selected="false">People</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="processes-tab" data-toggle="pill" href="#processes" role="tab" aria-controls="processes" aria-selected="false">Processes</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="technology-tab" data-toggle="pill" href="#technology" role="tab" aria-controls="technology" aria-selected="false">Technology</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="special-tab" data-toggle="pill" href="#special" role="tab" aria-controls="special" aria-selected="false">Special</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent" style={{ margin: `3rem 0 6rem 0`}}>
          <div className="tab-pane fade show active" id="places" role="tabpanel" aria-labelledby="places-tab">
            <div className="mb-5">
              <AwardCategoryShortlist title="Workplace &amp; Workspace Transformation" gridColor="#d23464" icon="computer-desk">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Competition and Markets Authority" project="The Big Move" logo="cma" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Defence Infrastructure Organisation" project="Smarter Working Programme" logo="dio" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Department of Finance" project="Agile Working Trial for the Northern Ireland Civil Service" logo="dof" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="HM Revenue &amp; Customs" project="Spaces &amp; Places" logo="hmrc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="National Savings &amp; Investments" project="Drummond Gate Office Refit" logo="nsandi" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Office of the Police and Crime Commissioner for Humberside" project="A Workplace Transformation To Reflect Our Culture" logo="hpcc" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Asset Management Innovation" gridColor="#d23464" icon="cog">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Isle of Wight Council" project="Workplace Transformation" logo="iwcouncil" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Property Services" project="NHS Open Space" logo="nhs-property" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Nottinghamshire Healthcare NHS Foundation Trust" project="Electronic Observation Solution" logo="nottinghamshire-nhs" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Tameside Metropolitan Borough Council" project="Tameside One" logo="tameside-mbc" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Efficiency in The Property Portfolio" gridColor="#d23464" icon="office">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Competition and Markets Authority" project="The Big Move" logo="cma" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Department for Education" project="One Coventry - Transforming to a Smarter DfE" logo="dfe" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Property Services" project="120 The Broadway" logo="nhs-property" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="The Queen Elizabeth Hospital King's Lynn NHS Foundation Trust" project="The Same Day Emergency Care Unit" logo="qehkl" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Defence Infrastructure Organisation" project="Smarter Working Programme" logo="dio" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Driver &amp; Vehicle Licencing Agency" project="DVLA Chat Bot" logo="dvla" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Rosebery Housing Association" project="Self Service Portals" logo="rha" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="The Queen Elizabeth Hospital King's Lynn NHS Foundation Trust" project="The Same Day Emergency Care Unit" logo="qehkl" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
          </div>
          <div className="tab-pane fade" id="people" role="tabpanel" aria-labelledby="people-tab">
            <div className="mb-5">
              <AwardCategoryShortlist title="Corporate Leadership, Management &amp; Culture" gridColor="#d23464" icon="people">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Barnsley Metropolitan Borough Council" project="#DigitalFirst" logo="bmbc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Crown Prosecution Service" project="Smarter Working at the CPS" logo="cps" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Department for Education" project="Building a culture of Smarter Working in DfE" logo="dfe" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="HM Revenue &amp; Customs" project="Smarter Ways of Working 'Simply the way we do things here'" logo="hmrc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Royal Cornwall Hospitals NHS Trust" project="Our Journey to Brilliant" logo="rchnhs" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Skills &amp; Succession Planning" gridColor="#d23464" icon="cog-person">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Coleg Sir Gar" project="Share, Collaborate, Develop - A Training Journey with Coleg Sir Gar/Coleg Ceredigion" logo="coleg" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Department for Education" project="Building Skills for Smarter Working in DfE" logo="dfe" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="North East Lincolnshire Council" project="Leading by Example: NELC Talent &amp; Leadership Academy" logo="nelc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Royal Berkshire Fire and Rescue Service" project="Remotely Managed Stations and Flexi Duty Officers" logo="rbfb" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Workforce Management" gridColor="#d23464" icon="people-arrow">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="National Savings &amp; Investments" project="Drummond Gate Office Refit" logo="nsandi" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Employers" project="Developing Recruiting Systems to Enhance the Experience for the Armed Forces Community" logo="nhsemployers" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS London Procurement Partnership" project="The Workforce Alliance Permanent &amp; Temporary Staffing Solutions Suite" logo="nhslpp" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Royal Berkshire Fire and Rescue Service" project="Remotely Managed Stations and Flexi Duty Officers" logo="rbfb" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Greater Manchester Police in partnership with HCL Technologies" project="HCL OPTIK – Mobilising and Transforming Greater Manchester Operational Policing" logo="gmphcl" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Leicestershire Partnership NHS Trust" project="Implementation of AutoPlanner within District Nursing" logo="lpnt" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Midland Partnership NHS Foundation Trust" project="Flu Vaccination APP" logo="mpnt" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Yorkshire Ambulance Service NHS Trust" project="999 and IUC Real Time Operational Performance App" logo="yas" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
          </div>
          <div className="tab-pane fade" id="processes" role="tabpanel" aria-labelledby="processes-tab">
            <div className="mb-5">
              <AwardCategoryShortlist title="Automation (Digital / Robotic / AI)" gridColor="#d23464" icon="brain">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Driver &amp; Vehicle Standards Agency" project="The Driving Examiner Services App" logo="dvsa" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="HM Revenue &amp; Customs" project="Digitalisation of Data Gathering" logo="hmrc" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Shared Business Services" project="Robotic Process Automation (RPA) NHS Finance Project" logo="nhssbs" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="West Midlands Police" project="Centre for Applied Automation" logo="wmp" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Data &amp; Interoperability" gridColor="#d23464" icon="atom">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Business Services Authority" project="Real Time Exemption Checker" logo="nhsbsa" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Nottinghamshire County Council" project="Business Intelligence – Power BI Dashboards" logo="ncc" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS South, Central and West" project="Development, Delivery &amp; Support of Multiple Integrated Care Record &amp; Interoperability Programmes" logo="scw" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NS&amp;I Government Payment Services" project="Help to Save" logo="nsandigps" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Paperless" gridColor="#d23464" icon="paper-shred">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Driver &amp; Vehicle Licensing Agency" project="Company Tachograph Card Digital Transformation" logo="dvla" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Driver &amp; Vehicle Standards Agency" project="The Driving Examiner Services App" logo="dvsa" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Foreign &amp; Commonwealth Office" project="Paperless Board Meetings and Beyond" logo="fco" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Gower College Swansea" project="Health Disclosures and Health Care Plans" logo="gcs" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Healthy Wirral Partners" project="Health Information Exchange (HIE)" logo="hwp" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Sandwell Metropolitan Borough Council" project="Rethinking HR to Create Paperless Working" logo="smbc" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Aintree University Hospital NHS Foundation Trust" project="Synopsis IQ" logo="auhnt" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Croydon Health Services NHS Trust" project="Improving Care, Flow and Staff Satisfaction" logo="chsnt" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="DWP Digital" project="Data Control Service" logo="dwpd" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="London Borough of Bexley &amp; Docobo" project="NHS Digital Pathfinders" logo="lbb" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Marine Management Organisation" project="Explore Marine Plans" logo="mmo" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Swindon Borough Council" project="Jadu Digital Hub" logo="sbc" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
          </div>
          <div className="tab-pane fade" id="technology" role="tabpanel" aria-labelledby="technology-tab">
            <div className="mb-5">
              <AwardCategoryShortlist title="Unified Communications" gridColor="#d23464" icon="circle-dots">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Barnsley Metropolitan Borough Council" project="#DigitalFirst" logo="bmbc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Cambridgeshire and Peterborough CCG" project="Using Facebook Groups to Increase Minor Injury Units Usage" logo="cpccg" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Department of Finance" project="Agile Working Trial for the Northern Ireland Civil Service" logo="dof" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Rosebery Housing Association" project="Self Service Portals" logo="rha" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Efficiency Savings" gridColor="#d23464" icon="pound-sign">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Isle of Wight Council" project="ASC Pre-paid Card" logo="iwcouncil" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Business Services Authority" project="Manage Your Service - NHS Community Pharmacist Consultation Service Launch" logo="nhsbsa" />
                  </div>
                  
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Digital" project="Health &amp; Social Care Network: Better Connectivity for Health" logo="nhsdigital" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Police ICT" project="Electronic Witness Statements for UK Police Forces" logo="policeict" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Small Scale Project" gridColor="#d23464" icon="inward-arrows">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Dorset Council" project="Supporting Children &amp; Young People to Access Education" logo="dc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="East Lancashire Hospitals NHS Trust" project="Booking Centre Application" logo="elhnt" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="HM Courts &amp; Tribunals Service" project="Screens Project" logo="hmcts" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Marine Management Organisation" project="Explore Marine Plans" logo="mmo" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Medway Council" project="Revenues &amp; Benefits Data Migration" logo="mc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Business Services Authority" project="Am I Exempt?" logo="nhsbsa" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Argyll and Bute Council" project="Keep in the Loop: Pro-active Engagement Service" logo="abc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Disclosure and Barring Service" project="Basic DBS Check" logo="dbs" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Durham Police Constabulary &amp; SentrySIS" project="Technology as a Policing Enabler" logo="dpc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="London Ambulance Service NHS Trust" project="Collaboration with GoodSAM" logo="las" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="NHS Business Services Authority" project="Registering to Issue Digital Maternity Exemptions" logo="nhsbsa" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Sandwell and West Birmingham NHS Trust" project="Developing Electronic Communications" logo="swbnt" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Wirral Community Health and Care NHS Foundation Trust" project="Standards Assurance Framework for Excellence" logo="wchc" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
          </div>
          <div className="tab-pane fade" id="special" role="tabpanel" aria-labelledby="special-tab">
            <div className="mb-5">
              <AwardCategoryShortlist title="Special Recognition" gridColor="#d23464" icon="trophy">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Education and Skills Funding Agency" project="AMSD Knowledge Management and Smarter Working" logo="esfa" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="HM Revenue &amp; Customs" project="Spaces and Places" logo="hmrc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Nottinghamshire County Council" project="Business Intelligence – Power BI Dashboards" logo="ncc" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Tameside Metropolitan Borough Council" project="Tameside One" logo="tameside-mbc" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="The Queen Elizabeth Hospital King's Lynn NHS Foundation Trust" project="The Same Day Emergency Care Unit" logo="qehkl" />
                  </div>
                </Row>
              </AwardCategoryShortlist>
            </div>
            <div className="mb-5">
              <AwardCategoryShortlist title="Smarter Working Project of the Year" gridColor="#d23464" icon="combination">
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Competition and Markets Authority" project="The Big Move" logo="cma" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Crown Prosecution Service" project="Smarter Working at the CPS" logo="cps" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Defence Infrastructure Organisation" project="Smarter Working Programme" logo="dio" />
                  </div>
                </Row>
                <Row>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="Department for Education" project="Building a Culture of Smarter Working in DfE" logo="dfe" />
                  </div>
                  <div className="col-lg">
                    <ShortlistOrganisation organisation="HM Revenue &amp; Customs" project="Smarter Ways of Working 'Simply the way we do things here'" logo="hmrc" />
                  </div>

                </Row>
              </AwardCategoryShortlist>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  </Background>
)

export default ShortlistPage
